.FollowCard {

    width:85%;
    background-color: var(--cardBg);
    border-radius: 10px;
    padding: 20px;
    display:flex;
    /* flex-direction: column; */
    gap:15px;
    /* position: relative; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.userIconAndDetailSection {
    display:flex;
    justify-content: center;
    gap: 1rem;
}
.FollowCard img {
    /* position:absolute; */
    display: inline-block;
    height:60px;
    width: 60px;
    border-radius: 50%; 
    object-fit: cover;
    /* top:-50%; */
     /* position: absolute;  */
    /* left:50%; 
    bottom: -50%; */
    /* transform: translate(-50%, -50%); */
}

.userDetailSection {
   display:flex;
   flex-direction: column;
   justify-content: space-between;
   /* align-items: flex-start; */
}

.followBtnSection {
  display: inline-block;
}