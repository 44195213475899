.likeListModalInner {
  background-color: #ffffff;
    padding: 10px 15px 25px 15px;
    width: 28%;
    top: 50%;
    left: 50%;
    position: relative;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    /* height: 50%; */
    box-shadow:  6px 6px 12px #5a5a5a,
    -6px -6px 12px #a6a6a6;
}

.LikeList {
  display: flex;
  flex-direction: column;
  justify-content:center ;
  align-items: center;
  gap: 1rem;
  margin-top: 25px;
  /* background-color: #ffffff; */
 
}

