.PostSide {
    /* width: 70%;
    display:flex;
    flex-direction: column;
    min-height: 100vh; */
}

.creators-profilecard {
    height: 500px;
    color:pink;
}