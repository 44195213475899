.Auth {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    
    position: relative;
    background-color: var(--bodyBg);
   
  
}





.authSection{
    /* display: flex;
    align-items:center;
    justify-content:center;
    flex-direction: column;
    gap: 1.2rem;
    background-color: var(--cardBg);
    border-radius: 10px;
    padding: 20px;
    width:20%; */

}

.authSection .textInput {
    height: 2rem;
}

.authSection > div{
    display: flex;
    gap: 1rem;
    width: 100%;
}

.authSection > button {
    margin-left: auto;
}

.passwordSection{
    /* display:flex;
    flex-direction: column;
    position: relative; */
}
.passwordSection > div {
    /* position: relative;
    width:100%; */

}

.pwdIcon{
  position:absolute;
  right:2%;
  top: 20%;
  cursor: pointer;
  

}


.signUpOrLogin {
    cursor:pointer;
    /* transition: all 0.2s linear;  */
}

.signUpOrLogin:hover {
    color: var(--btnBg);
}

.authSection .signInOrLoginBtn {
    display:flex;
    flex-direction: column;
    align-items: center;
    gap:0.4rem;
}

.Auth > h6 {
  /* text-align: right;
   font-size:14px;
   width:100%; */
   /* margin-right:-170px; */
}


