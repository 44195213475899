.EditProfile {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    width:50%; 
  
     margin: 20px auto;
     border-radius: 20px;
     background-color:var(--cardBg);
     overflow-x: clip;
     padding: 20px; 
     position: relative;
     box-shadow:  6px 6px 12px #5a5a5a,
     -6px -6px 12px #a6a6a6;
}

.editProfileImg {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.profileImg {
    position:relative;
  
    /* border:2px solid rgba(150, 146, 146, 0.2); */
   background-color:rgba(150, 146, 146, 0.2)
   
}

.profileBgImg {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    position:relative;
    border-radius: 20px;
}

.previewProfileBgImg {
    position:absolute;
    width:100%;
    height: 100%;
    top:0%;
}
.previewProfileBgImg  img {
    /* width:100%; */
    width: 100%;
    position:absolute;
    border-radius: 20px;
    height: 100%;
    object-fit:cover;
    object-position:50% 25%
   }


  .profileIconImg {
    /* width: 25%; */
    position:absolute;
    height:150px;
    width: 150px;
    border-radius: 50%; 
    /* top:-50%; */
     /* position: absolute;  */
    left:50%; 
    bottom: -50%;
    transform: translate(-50%, -50%);
    /* display: inline-block */
    
   }   

   .photo {
    display: inline-block;
    /* position:absolute; */
    z-index: 2;
    width:25px;
     cursor: pointer;
    /* left:0; */
    color: #ffffff;
}   



.profileBgImg .photo {
    position:absolute;
    z-index:2;
    left:50%;
    transform: translate(-50%, -50%);
}

.profileBgImg .fileBaseContainer{
    position:absolute;
    left:50%;
    transform: translate(-50%, -50%);
    opacity:0;
    z-index:5;
    width:25px;
    cursor:pointer;
}

 .profileIconImg .photo {
    top: 60%;
    left:-4%;
    position:absolute;
   
 
   }  



   .profileIconImg .fileBaseContainer {
    cursor:pointer;
    position: absolute;
    top: 60%;
    left:-4%;
    opacity:0;
    z-index:5;
    width:25px;
  
    
}

.fileBase {
    display:inline-block;
    width:100%;
}


.editProfileImg input[type=file]::-webkit-file-upload-button{
    cursor:pointer;
    width:25px;
 
}
.previewProfileIconImg  img {
    width: 100%;
    height:100%;
    border-radius: 50%; 
    position: absolute;
    object-fit: cover;
    z-index:10;
    left:50%; 
     bottom: -50%;
    transform: translate(-50%, -50%);
    display:inline-block;
   
   }


.previewProfileBgImg .fa-xmark {
    position: absolute;
    right:-5px;
    top: -16px;
    z-index:1;
    /* top:107%; */
    /* color:#ffffff; */
}

.previewProfileIconImg {
    position:absolute;
    /* border:2px solid pink ; */
    border-radius:50%;
    width:100%;
    height:100%;
    left:50%; 
    bottom: -50%;
    transform: translate(-50%, -50%);

}
.previewProfileIconImg .fa-xmark {
    position: absolute;
    width:20px;
    top:60%;
    right:-10%;
    z-index: 20;

}

.profileDetail {
    margin-top: 3rem;
    width: 100%;
    display:flex;
    flex-direction: column;
    gap:1rem;
}   


.saveBtnSection {
    margin-left: auto;
}
