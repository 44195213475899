.comment-section {
    /* display:flex;
    flex-direction: column;
    gap:1rem; */
}


.commentBtn {
    /* display: flex;
    gap: 1rem;
    justify-content: center; */
}

.commentDetails {
    /* display: flex; 
    flex-direction: column; */
}

.comments { 
    /* display:flex;
    flex-direction: column;
    gap: 15px; */

}
.comment {
 /* display:flex;
 align-items: center;
 max-height: 100px;
 overflow-y: auto;
 display:flex;
 gap: 1rem;
 margin-bottom:10px; */

}

.commentorIconImg {
    /* display: inline-block;
    height:40px;
    width: 40px;
    border-radius: 50%; 
    object-fit: cover;
    cursor: pointer; */
}

.commentedTime {
    font-size:10px
}

.commentUsername {
    /* cursor: pointer; */
}

.commentAndFunctions {
    /* display: flex;
    justify-content: space-between; */

}

.commentFunctions {
  /* cursor: pointer;
  position:relative; */
}