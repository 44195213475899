.followListSection {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.FollowList {
 
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        background-color: #ffffff;
        margin: 20px 0;
        padding: 20px;
        border-radius: 15px;
        /* width:100%; */
    
  
        width: 100%;
          
        min-height: fit-content;
            
            
}