
.followBtn {
    /* position:absolute; 
    right:60px;
    top:5px; */
    display: inline-block;
    /* position:absolute; */
    /* top:50%; */
    /* transform: translateY(-50%); */

    
}